pre {
    background: #171717;
    border-radius: 5px;
    color: #fff;
    padding: 14px 18px;
}
code {
    white-space: pre-wrap;
    word-break: break-all;
}
p {
    word-break: break-all;
}
.custom-h4 span:nth-of-type(1){
    font-weight: bold;
    margin-right: 40px;
}